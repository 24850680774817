import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root',
})
export class MondayService {
  pageSize = 200;
  constructor(public http: HttpClient, private requestService: RequestService) {
  }
  baseURL = environment.monday_api_url;
  header: any = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*', // Required for CORS support to work
    Authorization:
      'n7m0c2b9z6C9u3w9eliufhfnjkdnbcaakjdhgasdhflhaigkladsglghakoiruegkajsnbnvvjvbv',
  };

  async getAllAgents(queryParams: string = '') {
    let requestType = 'agencyContacts';
    let type = this.requestService.currentUser.type;
    let email = this.requestService.currentUser.email;
    let typeEnum = type == '0' ? 'admin' : type == '1' ? 'agency' : 'agent';
    const response = await this.http
      .post<any>(
        this.baseURL + '/api/monday',
        {
          reqType: requestType,
          userType: typeEnum,
          email: email,
          queryParams: queryParams,
        },
        { headers: this.header }
      )
      .toPromise();
    return response;
  }

  async getAgentByEmail(email: string) {
    let requestType = 'byEmail';
    const response = await this.http
      .post<any>(
        this.baseURL + '/api/monday',
        {
          reqType: requestType,
          userType: 'admin',
          email: email,
        },
        { headers: this.header }
      )
      .toPromise();
    return response;
  }

  async getAgencies() {
    let requestType = 'agencies';
    let type = this.requestService.currentUser.type;
    let email = this.requestService.currentUser.email;
    let typeEnum = type == '0' ? 'admin' : type == '1' ? 'agency' : 'agent';
    const response = await this.http
      .post<any>(
        this.baseURL + '/api/monday',
        {
          reqType: requestType,
          userType: typeEnum,
          email: email,
        },
        { headers: this.header }
      )
      .toPromise();
    return response;
  }
}
